import '../styles/main.scss'
import { setupCountdown } from './countdown';

const params = new URLSearchParams(window.location.search);

const eventId = +params.get('eventId');
const returnUrl = params.get('returnUrl');
let position = params.get('position') ? +params.get('position') : undefined;

const text = document.getElementById('position');

const nth = (n: number) => ["st", "nd", "rd"][((n + 90) % 100 - 10) % 10 - 1] || "th";

const getRandomNumber = () => Math.round(Math.random() * (9 - 3) + 6);
const unsupportedDialog = document.getElementById('unsupported') as HTMLDialogElement;

const setPosition = (perMinute: number) => {
  updatePosition(position);

  if (position === 1) return;

  const rando = getRandomNumber();
  const interval = 60 / perMinute * 1000 * rando;

  if (position > rando + 1) {
    position -= rando;
  } else {
    position = 1;
  }
  setTimeout(async () => setPosition(perMinute), interval);
}

const updatePosition = (position: number) => {
  text.innerText = `You're estimated to be ${position}${nth(position)} in the queue`;

  const url = new URL(window.location.href);
  url.searchParams.set("position", position.toString());
  history.pushState(undefined, undefined, url);
}

fetch(`/timings/${eventId}`, { method: 'GET' }).then(x => x.json().then(({ perMinute }: { perMinute: number }) => {
  if (position && !isNaN(position)) {
    setupCountdown(position, perMinute);
    setPosition(perMinute);
  }
  const timeout = position && !isNaN(position) ? getRandomNumber() * 2000 : 1000;
  setTimeout(async () => checkVoucher(perMinute), timeout);
}));

const checkSupported = (): boolean => {

  const hasUserId = document.cookie.includes('TryBooking_WaitingRoomId=');

  if (hasUserId) return true;


    const dialogLink = document.getElementById('dialog-link') as HTMLAnchorElement;
    dialogLink.href = window.location.href;

    const browserLink = document.getElementById('browser-link') as HTMLElement;
    browserLink.innerText = window.location.href;

    unsupportedDialog.classList.remove('dialog-safari-closed');
    
    if('showModal' in unsupportedDialog) {
      unsupportedDialog.showModal();
    }else{
      (unsupportedDialog as HTMLElement).classList.add('dialog-safari-open');
    }
    


  return false;
}

const checkVoucher = async (perMinute: number) => {
  const response = await fetch(`/check/${eventId}?returnUrl=${returnUrl}`, { method: 'GET' });
  const body = await response.json();

  if (!checkSupported()) {
    // Cookies aren't supported so break the timeout loop. User must open new tab.
    return;
  }

  if (body.hasToken) {
    window.location.href = body.redirectUrl;
    return;
  }


  if (body.position) {
    position = body.position;
    setupCountdown(position, perMinute);
    setPosition(perMinute);
  }
  const adjustment = position > 10 ? position / 2 : position * 2
  let timer = 60 / perMinute * adjustment;
  timer = timer < 5 ? 5 : timer > 60 ? 60 : timer;

  setTimeout(async () => checkVoucher(perMinute), timer * 1000);
}
