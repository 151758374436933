const element = document.getElementById("timer");
let interval: NodeJS.Timeout | undefined = undefined;



export const setupCountdown = (position: number, perMinute: number) => {
  if (interval) { 
    clearInterval(interval);
  }
  // Set the date we're counting down to
  var countDownDate = new Date().getTime();

  // Calculate wait time appropriate based upon position & per minute + 30 second buffer
  countDownDate += Math.ceil(60 / perMinute * position * 1000 + 30000);

  // Update the count down every 1 second
  interval = setInterval(function() {
    // Get today's date and time
    var now = new Date().getTime();

    // Find the distance between now and the count down date
    var distance = countDownDate - now;

    if (distance < 0) {
      clearInterval(interval);
      document.getElementById("timer").innerHTML = "Please hold....";
      return;
    }
    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // Display the result in the element with id="demo"
    let content = '';

    if (days > 0) {
      content += days + "d ";
    }

    if (hours > 0) {
      content += hours + "h ";
    }

    if (minutes > 0) {
      content += minutes + "m ";
    }

    content += seconds + "s";
    

    element.innerHTML = content;

    // If the count down is finished, write some text
  }, 1000);
};

